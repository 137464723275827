import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './Home.js';
import { Helmet } from 'react-helmet';


function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
         
        </Helmet>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>

      </div>
    </Router>
  );
}

export default App;
