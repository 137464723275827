import logo_linkedin from '../src/linkedin_bug.png';

const Home = () => {
    return (
        <div className="home">
            <div className="header">
                <div className="stars"></div>
                <div className="twinkling"></div>
                <h2 className="hey">Hey, I'm</h2>
                <h1 className="name"><a href="/">Marek Kalabza</a></h1>
                <div className="bounce">
                    <a href="#connect" className="arrow_href">
                        <i className="arrow down"></i>
                    </a>
                </div>
            </div>
            <div className="connect container">
                <h2 id="connect" className="title">Connect</h2>
                <p>I lived in Ostrava.</p>

                <p>I moved to Brno for college.</p>

                <p>I now live in Ostrava.</p>

                <p>I watch a lot movies and i read books too.</p>

                <p>If you don't try then you'll never know.</p>

                <a href="https://cz.linkedin.com/in/marek-kalabza" className="linkedin_href">
                    <img src={logo_linkedin} alt="LinkedIn logo" width="100vw" />
                </a>

            </div>
        </div>
    );
}

export default Home;